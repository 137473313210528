import { PHASE_PRODUCTION_BUILD } from 'next/constants';

export const getEnv = <T extends 'string' | 'boolean' | 'number'>(
  name: string,
  type: T,
  default_?: any
): T extends 'string' ? string : T extends 'boolean' ? boolean : number => {
  const env = process.env[name] ?? (default_ !== undefined ? `${default_}` : undefined);

  if (env === undefined) {
    throw new Error(`Config error: ${name} is not defined.`);
  }

  if (type === 'boolean') {
    if (env.toLowerCase() === 'true') return true as any;
    if (env.toLowerCase() === 'false') return false as any;
    throw new Error(`Config error: ${name} is not a boolean. Value ${env}`);
  }
  if (type === 'number') {
    if (!env.match(/^-?\d+$/)) throw new Error(`Config error: ${name} is not a number. Value ${env}`);
    const number = parseInt(env);
    return number as any;
  }
  return env as any;
};

interface Config {
  redisMultiCacheEnabled: boolean;
  isBuild: boolean;
  WM_AFFILIATION_CODE: string;
  WSG_AFFILIATION_CODE: string;
  SITE_DOMAIN: string;
  SHOW_AD_BANNER: boolean;
  SPORT_PAGE_SUGGESTION_LENGTH: number;
  CMS_URL: string;
  CMS_ASSETS_URL: string;
  AHOTU_URL: string;
  SEND_FEEDBACK: boolean;
  raceIndex: string;
  eventIndex: string;
  autosuggestIndex: string;
  PRISMA_REDIS_CACHE_HOST: string;
  PRISMA_REDIS_CACHE_TLS: boolean;
  PRISMA_REDIS_CACHE_PORT: number;
  isProduction: boolean;
  showMissingTranslations: boolean;
  isrRevalidateInSeconds: number;
  keepConsole: boolean;
}

export const config: Config = {
  redisMultiCacheEnabled: getEnv('MULTICACHE_ACTIVATE_REDIS', 'boolean', false),
  isBuild: getEnv('NEXT_PHASE', 'string', '') === PHASE_PRODUCTION_BUILD,
  WM_AFFILIATION_CODE: 'worlds_marathons',
  WSG_AFFILIATION_CODE: 'wsg_affiliation',
  SITE_DOMAIN: process.env.NEXT_PUBLIC_SITE_DOMAIN,
  SHOW_AD_BANNER: false,
  SPORT_PAGE_SUGGESTION_LENGTH: 6,
  CMS_URL: getEnv('CMS_URL', 'string', ''),
  CMS_ASSETS_URL: `${process.env.NEXT_PUBLIC_SITE_DOMAIN}/assets/`,
  AHOTU_URL: getEnv('AHOTU_URL', 'string', ''),
  SEND_FEEDBACK: getEnv('SEND_FEEDBACK', 'boolean', false),
  raceIndex: getEnv('OPEN_SEARCH_RACE_INDEX', 'string', ''),
  eventIndex: getEnv('OPEN_SEARCH_EVENT_INDEX', 'string', ''),
  autosuggestIndex: getEnv('OPEN_SEARCH_EVENT_AUTOSUGGEST_INDEX', 'string', ''),
  PRISMA_REDIS_CACHE_HOST: getEnv('PRISMA_REDIS_CACHE_HOST', 'string', ''),
  PRISMA_REDIS_CACHE_TLS: getEnv('PRISMA_REDIS_CACHE_TLS', 'boolean', false),
  PRISMA_REDIS_CACHE_PORT: getEnv('PRISMA_REDIS_CACHE_PORT', 'number', 6379),
  isProduction: getEnv('AHOTWO_ENV', 'string', '') === 'ahotwo-prod',
  isrRevalidateInSeconds: 5 * 60,
  showMissingTranslations: getEnv('NEXT_PUBLIC_SHOW_MISSING_TRANSLATIONS', 'boolean', true),
  keepConsole: getEnv('KEEP_CONSOLE', 'boolean', false)
};

export default config;
