export default function AhotuImageLoader({ src, width, quality }) {
  const format = 'webp';

  if (src.charAt(0) !== '/' && !src.startsWith('https://')) {
    src = `https://images.ahotu.com/${src}`;
  }

  if (src.startsWith('https://images.ahotu.com/')) {
    return `${src}?w=${width || 1920}&q=${quality || 100}&f=${format}`;
  }

  // images from cms
  if (src.match(/^https?:\/\/.+\/assets\/.*/)) {
    return `${src}?width=${width}&quality=${quality || 75}`;
  }

  return src;
}
